import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import SectionTitle from '../BrandingSectionTitle/BrandingSectionTitle.js';
import downloadIcon from '../../images/icn-download-blue.svg';
import checkIcon from '../../images/icn-check-green.svg';
import clearIcon from '../../images/icn-clear-red.svg';

import * as styles from './LogoAndIcons.module.scss';

const logoUrl = 'https://static.iterative.ai/branding';

export const logos = [
  {
    imgName: 'iterative',
    link: `${logoUrl}/iterative.zip`,
    name: 'iterative',
  },
  {
    imgName: 'dvc',
    link: `${logoUrl}/dvc.zip`,
    name: 'DVC',
  },
  {
    imgName: 'studio',
    link: `${logoUrl}/studio.zip`,
    name: 'Studio',
  },
  {
    imgName: 'cml',
    link: `${logoUrl}/cml.zip`,
    name: 'CML',
  },
];

export function PresentationalLogosAndIcons({ logosData }) {
  return (
    <section>
      <SectionTitle>Logo and icons</SectionTitle>
      <p className={styles.logoAndIcons__text}>
        Each file includes color and black &amp; white logos in SVG and PNG
        formats.
      </p>
      <ul className={styles.logoAndIcons__downloads}>
        {logosData.map(({ img, link, name, imgName }, i) => (
          <li key={i}>
            <a href={link} className={styles.download} download>
              <span className={styles.download__name}>{name} </span>
              <GatsbyImage
                className={cn(styles[`download__img_logo_${imgName}`])}
                image={getImage(img)}
                alt={`${name} logo`}
              />
              <div className={styles.download__text}>
                Download
                <Icon icon={downloadIcon} widthPixelSizes={[12, 12, 16]} />
              </div>
            </a>
          </li>
        ))}
      </ul>
      <div className={styles.guidelines}>
        <h3 className={styles.guidelines__title}>Usage guidelines</h3>
        <div className={styles.guidelines__section}>
          <h4
            className={cn(
              styles.guidelines__title,
              styles.guidelines__title_color_green,
              styles.guidelines__title_secondary
            )}
          >
            <Icon icon={checkIcon} widthPixelSizes={[16, 16, 16]} />
            Do
          </h4>
          <ul className={styles.guidelines__list}>
            <li>Use these logos and icons to link to Iterative websites</li>
            <li>
              Use these logos and icons in a blog post or news article about
              Iterative products
            </li>
            <li>
              Use these logos and icons to advertise integrations between your
              product and Iterative products
            </li>
            <li>
              Leave space around the logo from other objects in design to give
              the logo breathing room and prevent it from looking cluttered
            </li>
          </ul>
        </div>
        <div className={styles.guidelines__section}>
          <h4
            className={cn(
              styles.guidelines__title,
              styles.guidelines__title_color_red,
              styles.guidelines__title_secondary
            )}
          >
            <Icon icon={clearIcon} widthPixelSizes={[16, 16, 16]} />
            Don't
          </h4>
          <ul className={styles.guidelines__list}>
            <li>
              Use these logos and icons to brand your own website or application
            </li>
            <li>
              Manipulate the logos and icons or imply sponsorship, endorsement,
              or false association with Iterative
            </li>
            <li>
              Use any other artwork from our site without giving credit with
              proper link to source
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default function LogoAndIcons() {
  const logoImages = useStaticQuery(query);
  const logosData = logos.map((logo) => ({
    ...logo,
    img: logoImages[logo.imgName],
  }));

  return <PresentationalLogosAndIcons logosData={logosData} />;
}

const query = graphql`
  query getLogoImages {
    iterative: file(relativePath: { regex: "/logo-iterative.png$/" }) {
      childImageSharp {
        gatsbyImageData(height: 48, placeholder: BLURRED, quality: 90)
      }
    }
    dvc: file(relativePath: { regex: "/logo-dvc.png$/" }) {
      childImageSharp {
        gatsbyImageData(height: 48, placeholder: BLURRED, quality: 90)
      }
    }
    studio: file(relativePath: { regex: "/logo-studio.png$/" }) {
      childImageSharp {
        gatsbyImageData(height: 48, placeholder: BLURRED, quality: 90)
      }
    }
    cml: file(relativePath: { regex: "/logo-cml.png$/" }) {
      childImageSharp {
        gatsbyImageData(height: 48, placeholder: BLURRED, quality: 90)
      }
    }
  }
`;
