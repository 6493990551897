// extracted by mini-css-extract-plugin
export var officialColors = "OfficialColors-module--officialColors--gC7oa";
export var officialColors__text = "OfficialColors-module--officialColors__text--jxivY";
export var officialColors__colors = "OfficialColors-module--officialColors__colors--0tvaC";
export var officialColors__colorsRow = "OfficialColors-module--officialColors__colorsRow--R-0LR";
export var color = "OfficialColors-module--color--MCLkl";
export var color__example = "OfficialColors-module--color__example--QgTC5";
export var color__name = "OfficialColors-module--color__name--zQSUx";
export var bgExample = "OfficialColors-module--bgExample--llTHJ";
export var bgExample__text = "OfficialColors-module--bgExample__text--METn9";
export var bgExample__imgHalf = "OfficialColors-module--bgExample__imgHalf--QYcXD";
export var bgExample__imgHalf_light = "OfficialColors-module--bgExample__imgHalf_light--EY7hY";
export var bgExample__imgHalf_dark = "OfficialColors-module--bgExample__imgHalf_dark--M3QXm";