// extracted by mini-css-extract-plugin
export var logoAndIcons__text = "LogoAndIcons-module--logoAndIcons__text--gcv0+";
export var logoAndIcons__downloads = "LogoAndIcons-module--logoAndIcons__downloads--ZTKEO";
export var download = "LogoAndIcons-module--download--TyiCk";
export var download__name = "LogoAndIcons-module--download__name--hAg-7";
export var download__img_logo_cml = "LogoAndIcons-module--download__img_logo_cml--iKiuk";
export var download__img_logo_iterative = "LogoAndIcons-module--download__img_logo_iterative--decBv";
export var download__img_logo_dvc = "LogoAndIcons-module--download__img_logo_dvc--M-Nfu";
export var download__img_logo_studio = "LogoAndIcons-module--download__img_logo_studio--hHomZ";
export var download__text = "LogoAndIcons-module--download__text--QfEc-";
export var guidelines = "LogoAndIcons-module--guidelines--Hkj4a";
export var guidelines__title = "LogoAndIcons-module--guidelines__title--zKsSZ";
export var guidelines__title_secondary = "LogoAndIcons-module--guidelines__title_secondary--3uC0Z";
export var guidelines__title_color_red = "LogoAndIcons-module--guidelines__title_color_red--DgWwk";
export var guidelines__title_color_green = "LogoAndIcons-module--guidelines__title_color_green--O9Stb";
export var guidelines__section = "LogoAndIcons-module--guidelines__section--CiaRS";
export var guidelines__list = "LogoAndIcons-module--guidelines__list--HRjLE";