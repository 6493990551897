import React from 'react';
import cn from 'classnames';
import SectionTitle from '../BrandingSectionTitle/BrandingSectionTitle';
import Icon from '../Icon/Icon';
import iterativeIcon from '../../images/iterative_icon-color--square_vector.svg';
import cmlIcon from '../../images/cml_icon-color--square_vector.svg';
import studioIcon from '../../images/studio_icon-color--square_vector.svg';
import dvcIcon from '../../images/dvc_icon-color--square_vector.svg';
import {
  officialColors,
  officialColors__text,
  officialColors__colors,
  officialColors__colorsRow,
  color,
  color__example,
  color__name,
  bgExample,
  bgExample__imgHalf,
  bgExample__imgHalf_dark,
  bgExample__imgHalf_light,
  bgExample__text,
} from './OfficialColors.module.scss';

const colors = [
  {
    colorHex: '#13ADC7',
    name: 'Teal',
  },
  {
    colorHex: '#945DD5',
    name: 'Purple',
  },
  {
    colorHex: '#F46737',
    name: 'Orange',
  },
  {
    colorHex: '#7B61FF',
    name: 'Medium Purple',
  },
];

const bgExampleLogosUrls = [iterativeIcon, dvcIcon, cmlIcon, studioIcon];

const BgExampleLogos = () => {
  return bgExampleLogosUrls.map((url, i) => (
    <Icon key={i} icon={url} widthPixelSizes={[32, 40, 48]} />
  ));
};

function OfficialColors() {
  return (
    <section className={officialColors}>
      <SectionTitle>Official colors</SectionTitle>
      <p className={officialColors__text}>
        These brand colors may be helpful to you in harmonizing other objects in
        your content when referring to Iterative tools.
      </p>
      <div className={officialColors__colors}>
        {colors.map(({ colorHex, name }, i) => {
          if (i % 2 !== 0) return '';

          return (
            <div key={i} className={officialColors__colorsRow}>
              <div className={color} style={{ '--color': colorHex }}>
                <span className={color__example}>{colorHex}</span>
                <span className={color__name}>{name}</span>
              </div>
              {colors[i + 1] && (
                <div
                  className={color}
                  style={{ '--color': colors[i + 1].colorHex }}
                >
                  <span className={color__example}>
                    {colors[i + 1].colorHex}
                  </span>
                  <span className={color__name}>{colors[i + 1].name}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className={bgExample}>
        <div>
          <div className={cn(bgExample__imgHalf, bgExample__imgHalf_dark)}>
            <BgExampleLogos />
          </div>
          <div className={cn(bgExample__imgHalf, bgExample__imgHalf_light)}>
            <BgExampleLogos />
          </div>
        </div>
        <p className={bgExample__text}>
          Colors work on both dark and light backgrounds.
        </p>
      </div>
    </section>
  );
}

export default OfficialColors;
