import React from 'react';
import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import PageTitle from '../components/PageTitle/PageTitle';
import LogoAndIcons from '../components/LogoAndIcons/LogoAndIcons';
import OfficialColors from '../components/OfficialColors/OfficialColors';
import Footer from '../components/Footer/Footer';

export default function Brand() {
  return (
    <Layout title="Logo and Brand Assets">
      <Header>
        <PageTitle
          title="Logo and Brand Assets"
          text="Grab our official Iterative, DVC, Studio and CML brand resources and learn about usage guidelines."
        />
      </Header>
      <LogoAndIcons />
      <OfficialColors />
      <Footer />
    </Layout>
  );
}
